import React from "react"
import Styles from "./css/ty.module.scss"
import tyData from "./data/ty.json"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

class ty extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: tyData
    }
  }

showBrowsers(browsers){
  let display = false;
  if(getBrowser() === 'ie' && browsers.includes('ie')){
    display=true;
  }
  if(getBrowser() === 'firefox' && browsers.includes('firefox')){
    display=true;
  }
  if(getBrowser() === 'chrome' && browsers.includes('chrome')){
    display=true;
  }
  if(getBrowser() === 'edge' && browsers.includes('edge')){
    display=true;
  }
  if (getBrowser() === "safari" && browsers.includes("safari")) {
    display = true;
  }
  if(getBrowser() === 'other' && browsers.includes('other')){
    display=true;
  }
  if(display)
    this.setState({display: 'block'});
  else
    this.setState({display: 'none'});
}

componentDidMount() {
  if(this.props.browsers){
    this.showBrowsers(this.props.browsers);
  }
  else{
    this.setState({display: 'block'});
  }
  if(this.props.data){
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (isSafari()) {
      const browserData = Object.assign(this.state.data, this.state.data.safari);
      this.setState({ data: browserData });
    }
    if (isIeEdge()) {
      const browserData = Object.assign(this.state.data, this.state.data.edge);
      this.setState({ data: browserData });
    }


    });
  }
}
render() {
    return (
    <div className = {Styles.tyPageXPI}>
      <section>
        <header>
        <img src = {this.state.data.logo} className={Styles.logo} alt="AnswerBuddy"></img>
        </header>
        <h1>{this.state.data.heading}</h1>
        <video className={Styles.howToVideo} loop autoPlay muted><source src={this.state.data.howToVideo}></source></video>
            <ol>
                <li>
                    In the address bar, clear the URL and type "<b>Askme</b>"
                    and press <b>tab</b> or <b>space bar</b>.
                </li>
                <li>
                    The address bar will look like this:
                    <img src = {this.state.data.addLine} className={Styles.addLine} alt="AnswerBuddy"></img>
                </li>
                <li>
                    You're all set! You can now begin asking AnswerBuddy
                    anything in the address bar at any time.
                </li>
            </ol>         
        <RawFooter></RawFooter>
      </section>
    </div>

    )
  }
}

export default ty
