import Helmet from "react-helmet"
import React from "react"
//import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import TyPage from "src/components/typage"
import Disclaimer from "@tightrope/disclaimers"

const TyData = import("./data/data.json");

export default function ThankYou() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
      @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
     * {
    font-family: "Rubik", sans-serif;
     }
    
     footer {
      font-family: "Rubik", sans-serif;
      position:relative;
      width:100%;
      background:#fff;
      bottom:0;
      padding:12px 0 0;
      }
    
      footer a {
      color:#333;
      }
      
      footer ul {
      margin:0 auto;
      text-align: center;
      display:flex;
      justify-content: center;
      align-items: center;
      color:#333;
      font-size:13px;
      padding:0 0 12px 0;
      list-style:none;
      }
      
      footer ul li {
      padding:0 6px 0 0;
      }
      
      footer ul li:last-child {
      padding:0;
      }
      
      footer ul li:after {
      content: '|';
      padding:0 0 0 6px;
      }
      
      footer ul li:last-child:after {
      content:none;
      padding:0;
      }
     @keyframes zoom-in-zoom-out {
       0% {
         transform: scale(1, 1);
       }
       25% {
         transform: scale(1, 1);
       }
       50% {
         transform: scale(1.25, 1.25);
       }
       75% {
         transform: scale(1, 1);
       }
       100% {
         transform: scale(1, 1);
       }
     }
    @media only screen and (max-width: 992px) {
      footer ul {
      flex-wrap:wrap;
    }
  }
}
    `}
    </style>
  
    <title>Thank you for downloading</title>
    </Helmet>
    <section>
      <TyPage data={TyData}><Disclaimer></Disclaimer></TyPage>
      </section>
      
    </>
  )
}
